import update from 'immutability-helper'

const initialState = {
  initialized: false,
  isBusy: false,
  errors: [],
  successMessages: []
}

// //* EVENTS */
const INITIALIZE = 'app/INITIALIZE'
const DISPLAY_ERROR = 'app/DISPLAY_ERROR'
const DISPLAY_SUCCESS_MESSAGES = 'app/DISPLAY_SUCCESS_MESSAGES'
const SHOW_BUSY = 'app/SHOW_BUSY'

export function showAppBusy(show) {
  return { type: SHOW_BUSY, payload: show }
}

export function displayError(error) {
  return {
    type: DISPLAY_ERROR,
    payload: error
  }
}

export function displaySuccess(messages) {
  return {
    type: DISPLAY_SUCCESS_MESSAGES,
    payload: messages
  }
}

// //* Reducers *//
export default function reducer(state = initialState, action) {
  const { type, payload } = action
  switch (type) {
    case INITIALIZE:
      return update(state, { initialized: { $set: true } })

    case DISPLAY_ERROR:
      return update(state, { errors: { $push: [payload] } })

    case DISPLAY_SUCCESS_MESSAGES:
      return update(state, { successMessages: { $push: [payload] } })

    case SHOW_BUSY:
      return update(state, { isBusy: { $set: payload } })

    default:
      return state
  }
}
