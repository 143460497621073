import update from 'immutability-helper'
import { I18n } from 'react-redux-i18n'
import qwInbox from '../common/client/qwInbox'
import { displayError } from './app'
import { fetchCompositions } from './compositions'
import { FilterTypes } from '../common/enums'

const TOGGLE_FETCH_IN_PROGESSS = 'kdmValidityFilter/TOGGLE_FETCH_IN_PROGESSS'
const RECEIVED_EXPIRING_DAYS = 'kdmValidityFilter/RECEIVED_EXPIRING_DAYS'
const UPDATE_SELECTED_KDM_VALIDITY_DATA = 'kdmValidityFilter/UPDATE_SELECTED_KDM_VALIDITY_DATA'

const initialState = {
  selectedList: [],
  filterList: [],
  fetchInProgress: false
}

function formatFilterlist(payload) {
  const filterList = [
    {
      label: I18n.t('filterPanel.kdmValidity.kdmValidityOptions.validNow'),
      key: FilterTypes.KDM_VALIDITY_OPTIONS.VALID_NOW,
      type: FilterTypes.KDM_VALIDITY
    },
    {
      label: I18n.t('filterPanel.kdmValidity.kdmValidityOptions.future'),
      key: FilterTypes.KDM_VALIDITY_OPTIONS.FUTURE,
      type: FilterTypes.KDM_VALIDITY
    },
    {
      label: I18n.t('filterPanel.kdmValidity.kdmValidityOptions.expiring', { days: payload }),
      key: FilterTypes.KDM_VALIDITY_OPTIONS.EXPIRING,
      type: FilterTypes.KDM_VALIDITY
    },
    {
      label: I18n.t('filterPanel.kdmValidity.kdmValidityOptions.expired'),
      key: FilterTypes.KDM_VALIDITY_OPTIONS.EXPIRED,
      type: FilterTypes.KDM_VALIDITY
    }
  ]

  if (payload === 0) {
    return filterList.filter(filter => filter.key !== 'expiring')
  }

  return filterList
}

function receivedExpiringDays(payload) {
  return { type: RECEIVED_EXPIRING_DAYS, payload: formatFilterlist(payload) }
}

function toggleFetchInProgess(payload) {
  return {
    type: TOGGLE_FETCH_IN_PROGESSS,
    payload
  }
}

export function updateSelectedKdmValidity(payload) {
  return { type: UPDATE_SELECTED_KDM_VALIDITY_DATA, payload }
}

export function removeItemFromKdmValidityFilter(filterItem) {
  return (dispatch, getState) => {
    dispatch(
      updateSelectedKdmValidity(
        getState().kdmValidityFilter.selectedList.filter(
          kdmStatus => kdmStatus.key !== filterItem.key
        )
      )
    )
    dispatch(
      fetchCompositions(
        true,
        0,
        getState().kdmValidityFilter.selectedList.concat(getState().theatreFilter.selectedList)
      )
    )
  }
}

export function fetchFilterSettings() {
  return dispatch => {
    dispatch(toggleFetchInProgess(true))
    return qwInbox
      .get('/filter/options')
      .then(res => {
        dispatch(toggleFetchInProgess(false))
        dispatch(receivedExpiringDays(res.data.kdmExpiringInDays))
      })
      .catch(() => {
        dispatch(toggleFetchInProgess(false))
        dispatch(displayError(I18n.t('errors.fetchFilterSettings')))
      })
  }
}

export default function reducer(state = initialState, action) {
  const { type, payload } = action

  switch (type) {
    case TOGGLE_FETCH_IN_PROGESSS:
      return update(state, { fetchInProgress: { $set: payload } })
    case RECEIVED_EXPIRING_DAYS:
      return update(state, { filterList: { $set: payload } })
    case UPDATE_SELECTED_KDM_VALIDITY_DATA:
      return update(state, { selectedList: { $set: payload } })
    default:
      return state
  }
}
