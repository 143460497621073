import axios from 'axios'
import _ from 'lodash'
import update from 'immutability-helper'
import { I18n } from 'react-redux-i18n'
import cinemas from '../common/client/cinemas'

import qwInbox from '../common/client/qwInbox'
import { downloadKdm } from '../common/util/helper'

import { displayError } from './app'
import { updateSelectedTheatre } from './theatreFilter'
import { updateSelectedKdmValidity } from './kdmValidityFilter'
import { FilterTypes } from '../common/enums'

const ps = 5
const expectedPs = 10
const initialState = {
  compositions: [],
  offset: 0,
  hasMore: true,
  fetchInProgress: false,
  cplIds: [],
  enabledExpandAll: false,
  dataReceived: false,
  searchText: '',
  code: ''
}
let refetchArray = []

//* EVENTS */
const FETCH_COMPOSITIONS = 'compositions/GET_COMPOSITIONS'
const REMOVE_COMPOSITIONS = 'compositions/REMOVE_COMPOSITIONS'
const TOGGLE_FETCH_IN_PROGESSS = 'compositions/TOGGLE_FETCH_IN_PROGESSS'
const FETCH_COMPOSITIONS_ERROR = 'compositions/FETCH_COMPOSITIONS_ERROR'
const CPLS_OPEN_THEATRES = 'compositions/CPLS_OPEN_THEATRES'
const TOGGLE_EXPAND_COLLAPSE_ALL = 'compositions/TOGGLE_EXPAND_COLLAPSE_ALL'
const UPDATE_THEATRE_INFO = 'compositions/UPDATE_THEATRE_INFO'
const UPDATE_SEARCH_TEXT = 'compositions/UPDATE_SEARCH_TEXT'
const UPDATE_REFRESH_TIME = 'compositions/UPDATE_REFRESH_TIME'
const IS_DATA_FETCHING = 'compositions/IS_DATA_FETCHING'
const SET_CODE = 'compositions/SET_CODE'

const { CancelToken } = axios
let source = CancelToken.source()

//* Actions *//

export function updateRefreshTime(payload) {
  return {
    type: UPDATE_REFRESH_TIME,
    payload
  }
}

export function isDataFetching(payload) {
  return {
    type: IS_DATA_FETCHING,
    payload
  }
}

export function toggleExpandCollapseAll(payload) {
  return {
    type: TOGGLE_EXPAND_COLLAPSE_ALL,
    payload
  }
}

export function updateCplsToOpenTheatres(payload) {
  return {
    type: CPLS_OPEN_THEATRES,
    payload
  }
}

export function updateTheatreInfo(payload, meta) {
  return {
    type: UPDATE_THEATRE_INFO,
    payload,
    meta
  }
}

export function updateSearchText(payload) {
  return {
    type: UPDATE_SEARCH_TEXT,
    payload
  }
}

export function setCompositions(compositions, nextOffset, refetch) {
  return {
    type: FETCH_COMPOSITIONS,
    payload: {
      compositions,
      nextOffset,
      refetch
    }
  }
}

export function removeCompositions() {
  return {
    type: REMOVE_COMPOSITIONS
  }
}

function toggleFetchInProgess(bool) {
  return {
    type: TOGGLE_FETCH_IN_PROGESSS,
    bool
  }
}

export function fetchCompositionsError() {
  return {
    type: FETCH_COMPOSITIONS_ERROR
  }
}
export function setCode(code) {
  return {
    type: SET_CODE,
    payload: code
  }
}

export function loadTheatreDetails(theatreId, cplId) {
  return dispatch => {
    const url = `/compositions?theatreId=${theatreId}&compositionId=${cplId}`
    return qwInbox
      .get(url)
      .then(res => {
        return res.data
      })
      .catch(() => {
        dispatch(displayError(I18n.t('errors.fetchTheatreInfo')))
      })
  }
}

export function refetchCompostions(forceOffset = 0) {
  return (dispatch, getState) => {
    const { searchText } = getState().compositions
    const { offset } = getState().compositions
    const refetchOffset = forceOffset
    let url = `/compositions/summary?ps=${ps}&offset=${refetchOffset}`
    if (searchText) {
      url += `&q=${searchText}`
    }
    getState().theatreFilter.selectedList &&
      getState().theatreFilter.selectedList.forEach(t => {
        url += `&theatreId=${t.key}`
      })
    getState().kdmValidityFilter.selectedList &&
      getState().kdmValidityFilter.selectedList.forEach(t => {
        url += `&kdmStatus=${t.key}`
      })
    qwInbox
      .get(url, { cancelToken: source.token })
      .then(response => {
        if (refetchOffset < offset) {
          refetchArray.push(...response.data)
          dispatch(refetchCompostions(refetchOffset + ps))
        } else {
          dispatch(setCompositions(response.data, offset, true))
          refetchArray = []
        }
      })
      .catch(error => {
        if (!axios.isCancel(error)) {
          dispatch(displayError(I18n.t('errors.fetchCompositions')))
        }
      })
  }
}

export function fetchCompositions(fresh = false, forceOffset = 0, filters = []) {
  if (fresh && source) {
    source.cancel()
    source = CancelToken.source()
  }
  return (dispatch, getState) => {
    const { fetchInProgress, searchText } = getState().compositions
    if (filters.clear) {
      switch (filters.type) {
        case FilterTypes.THEATRES: {
          dispatch(updateSelectedTheatre([]))
          break
        }
        case FilterTypes.KDM_VALIDITY: {
          dispatch(updateSelectedKdmValidity([]))
          break
        }
        default:
      }
    } else if (!_.isEmpty(filters)) {
      let theatreFilterData = []
      let kdmValidityFilterData = []
      filters.forEach(filter => {
        switch (filter.type) {
          case FilterTypes.THEATRES: {
            theatreFilterData.push(filter)
            break
          }
          case FilterTypes.KDM_VALIDITY: {
            kdmValidityFilterData.push(filter)
            break
          }
          default:
        }
      })
      dispatch(updateSelectedTheatre(theatreFilterData))
      dispatch(updateSelectedKdmValidity(kdmValidityFilterData))
    }
    if (!fetchInProgress || fresh) {
      dispatch(toggleFetchInProgess(true))
      if (fresh) {
        dispatch(removeCompositions())
      }
      const offset = forceOffset || getState().compositions.offset
      let url = `/compositions/summary?ps=${ps}&offset=${offset}`
      if (searchText) {
        url += `&q=${searchText}`
      }
      getState().theatreFilter.selectedList &&
        getState().theatreFilter.selectedList.forEach(t => {
          url += `&theatreId=${t.key}`
        })
      getState().kdmValidityFilter.selectedList &&
        getState().kdmValidityFilter.selectedList.forEach(t => {
          url += `&kdmStatus=${t.key}`
        })
      qwInbox
        .get(url, { cancelToken: source.token })
        .then(response => {
          if (response.data.code) {
            console.log()
            dispatch(setCode(response.data.code))
            dispatch(setCompositions([], offset + ps, false))
          }
          else {
            dispatch(setCompositions(response.data, offset + ps, false))
          }
          if (expectedPs > ps && offset <= expectedPs) {
            dispatch(fetchCompositions(false, offset + ps))
          }
        })
        .catch(error => {
          if (!axios.isCancel(error)) {
            dispatch(displayError(I18n.t('errors.fetchCompositions')))
            dispatch(fetchCompositionsError())
          }
        }).finally(
        dispatch(toggleFetchInProgess(false))
        )
    }
  }
}

function getDownloadKDMURLs(cplIds, theatreIds) {
  let url = `/kdms/download/urls`
  const params = []
  params.push(cplIds.map(c => `c=${c}`).join('&'))
  params.push(theatreIds.map(t => `t=${t}`).join('&'))
  url = `${url}?${params.join('&')}`

  return qwInbox.get(url)
}

export function downloadKDMs(cplName, cplIds, theatreIds) {
  return dispatch => {
    getDownloadKDMURLs(cplIds, theatreIds)
      .then(response => {
        if (!_.isEmpty(response.data)) {
          downloadKdm(cplName, response.data)
        }
      })
      .catch(() => {
        dispatch(displayError(I18n.t('errors.downloadKDMs')))
      })
  }
}

export function sendKDMEmail(cplIds, theatreIds, payload, cb) {
  return () => {
    getDownloadKDMURLs(cplIds, theatreIds)
      .then(response => {
        const tempPayload = payload
        if (!_.isEmpty(response.data)) {
          response.data.forEach(({ id, filename, url }) => {
            tempPayload.kdms[id] = {
              filename,
              url
            }
          })
          cinemas
            .post('/api/kdms/email', tempPayload)
            .then(() => cb(true))
            .catch(() => cb(false))
        } else {
          cb(false)
        }
      })
      .catch(() => cb(false))
  }
}

function getCplIdsFromCompositions(compositions) {
  const cplIds = []
  compositions.forEach(cpl => {
    if (cpl.resourceType === 'composition') {
      cplIds.push(cpl.id)
    } else {
      cpl.compositions.forEach(c => cplIds.push(c.id))
    }
  })

  return _.uniq(cplIds)
}

function getCplIdsBasedOnTheatreCount(compositions) {
  const cplIds = []
  compositions.forEach(cpl => {
    if (cpl.resourceType === 'composition') {
      if (cpl.theatres.length === 1) {
        cplIds.push(cpl.id)
      }
    } else {
      cpl.compositions.forEach(c => {
        if (c.theatres.length === 1) {
          cplIds.push(c.id)
        }
      })
    }
  })

  return _.uniq(cplIds)
}

function getUpdatedCompositions(state, action) {
  const { payload, meta } = action
  let updatedComposition = {}
  let movieObj = {}
  let cplIdx = ''
  let movieIdx = ''
  let isMovie = false

  _.cloneDeep(state.compositions).forEach((c, idx) => {
    if (c.resourceType === 'composition' && c.id === meta.cplId) {
      isMovie = false
      updatedComposition = c
      cplIdx = idx
    } else if (c.resourceType === 'movie') {
      c.compositions.forEach((cp, cpIdx) => {
        if (cp.id === meta.cplId) {
          isMovie = true
          movieObj = c
          updatedComposition = cp
          cplIdx = cpIdx
          movieIdx = idx
        }
      })
    }
  })

  const theatreIndex = _.findIndex(updatedComposition.theatres, t => t.id === meta.theatreId)
  let theatre = updatedComposition.theatres[theatreIndex]
  const newTheatre = payload[0].theatres[0]
  const data = {}
  data.city = { $set: newTheatre.city }
  data.province = { $set: newTheatre.province }
  data.country = { $set: newTheatre.country }
  data.timezone = { $set: newTheatre.timezone }

  if (!_.isEmpty(newTheatre.kdmSummary)) {
    data.screens = { $set: newTheatre.screens }
    data.kdms = { $set: newTheatre.kdms }
    data.kdmDeliveryMethods = { $set: newTheatre.kdmDeliveryMethods }
    data.screenCount = {
      $set: newTheatre.screens ? newTheatre.screens.filter(s => s.isKdmIssued).length : 0
    }
    data.kdmSummary = { $set: newTheatre.kdmSummary }
  }

  if (newTheatre.dcp && !_.isEmpty(newTheatre.dcp)) {
    data.dcp = { $set: newTheatre.dcp }
  }
  theatre = update(theatre, data)
  updatedComposition = update(updatedComposition, {
    theatres: {
      $splice: [[theatreIndex, 1, theatre]]
    }
  })
  if (isMovie) {
    movieObj = update(movieObj, { compositions: { $splice: [[cplIdx, 1, updatedComposition]] } })
  }

  return {
    idx: isMovie ? movieIdx : cplIdx,
    updatedComposition: isMovie ? movieObj : updatedComposition
  }
}

//* Reducers *//

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case UPDATE_REFRESH_TIME: {
      return {
        ...state,
        timestamp: action.payload
      }
    }
    case IS_DATA_FETCHING: {
      return {
        ...state,
        fetching: action.payload
      }
    }
    case FETCH_COMPOSITIONS: {
      const data = {}
      const fresh = action.payload.nextOffset === ps
      data.compositions = !action.payload.refetch
        ? { $push: action.payload.compositions }
        : { $set: refetchArray }
      data.offset = { $set: action.payload.nextOffset }
      data.hasMore = { $set: action.payload.compositions.length >= ps }
      data.fetching = { $set: false }
      if (fresh) {
        data.dataReceived = { $set: true }
      }
      if (state.enabledExpandAll) {
        data.cplIds = { $push: getCplIdsFromCompositions(action.payload.compositions) }
      } else {
        data.cplIds = { $push: getCplIdsBasedOnTheatreCount(action.payload.compositions) }
      }
      return update(state, data)
    }
    case REMOVE_COMPOSITIONS: {
      const data = {}
      data.compositions = { $set: [] }
      data.offset = { $set: 0 }
      data.hasMore = { $set: true }
      data.dataReceived = { $set: false }
      data.cplIds = { $set: [] }

      return update(state, data)
    }

    case TOGGLE_FETCH_IN_PROGESSS: {
      return update(state, { fetchInProgress: { $set: action.bool } })
    }

    case FETCH_COMPOSITIONS_ERROR: {
      return update(state, {
        hasMore: { $set: false },
        fetchInProgress: { $set: false }
      })
    }

    case CPLS_OPEN_THEATRES:
      return update(state, {
        cplIds: { $set: action.payload }
      })

    case TOGGLE_EXPAND_COLLAPSE_ALL: {
      const data = { enabledExpandAll: { $set: action.payload } }
      if (action.payload) {
        data.cplIds = { $set: getCplIdsFromCompositions(state.compositions) }
      } else {
        data.cplIds = { $set: [] }
      }
      return update(state, data)
    }

    case UPDATE_THEATRE_INFO: {
      const { updatedComposition } = getUpdatedCompositions(state, action)
      const { compositions } = state
      const tempArray = []
      compositions.forEach(item => {
        if (item.id === updatedComposition.id) {
          tempArray.push(updatedComposition)
        } else {
          tempArray.push(item)
        }
      })
      return {
        ...state,
        compositions: tempArray
      }
    }
    // return update(state, {compositions: {$splice: [[idx, 1, updatedComposition]]}})

    case UPDATE_SEARCH_TEXT:
      return update(state, { searchText: { $set: action.payload } })
    case SET_CODE:
      return update(state,{code:{$set: action.payload}})

    default:
      return state
  }
}
